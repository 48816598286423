const ArrowNextInactive = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.90717 0.46967C1.20006 0.176777 1.67494 0.176777 1.96783 0.46967L7.96783 6.46967C8.26072 6.76256 8.26072 7.23744 7.96783 7.53033L1.96783 13.5303C1.67494 13.8232 1.20006 13.8232 0.90717 13.5303C0.614277 13.2374 0.614277 12.7626 0.90717 12.4697L6.37684 7L0.90717 1.53033C0.614277 1.23744 0.614277 0.762563 0.90717 0.46967Z"
      fill="#A4ADB7"
    />
  </svg>
)

export default ArrowNextInactive
