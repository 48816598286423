import { Link } from 'gatsby'
import { useContext, useState, useEffect } from 'react'
import { AccountContext } from 'src/components/account/context'
import { getDetailsFromTimestamp } from 'src/utils/getDetailsFromTimestamp'

import type { Transaction } from '../../types'
import { NoOrders } from './NoOrders'

const currency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

const PhysicalStoreOrders = () => {
  const { userDecathlon } = useContext(AccountContext)
  const [orders, setOrders] = useState<Transaction[]>([])

  function getPurchaseDate(purchaseDate: string) {
    const { day, month } = getDetailsFromTimestamp(purchaseDate)

    return `${day}.${month}`
  }

  useEffect(() => {
    const sortedOrders = userDecathlon?.getPurchase.sort((a, b) => {
      const dateA = new Date(a.transaction.transaction_date_time)
      const dateB = new Date(b.transaction.transaction_date_time)

      if (dateA > dateB) {
        return -1
      }

      if (dateA < dateB) {
        return 1
      }

      return 0
    })

    setOrders(sortedOrders?.map((order) => order.transaction) ?? [])
  }, [userDecathlon?.getPurchase])

  return orders?.length ? (
    <div className="mt-2">
      <ul className="font-inter flex text-neutral10 flex-col">
        {orders.map((order) => {
          return (
            <li
              key={order.transaction_id}
              className="py-[40px]  border-b border-neutral04 last:border-none"
            >
              <Link
                to={`/account/store-order/${order.transaction_id}`}
                className="flex justify-between  w-full"
              >
                <div className="purchase-info">
                  <div className="text-sm sm:text-base font-semibold">
                    Comprado dia {getPurchaseDate(order.transaction_date_time)}
                  </div>
                  <div className="store-and-price mt-1 flex items-center">
                    <div className="text-xs sm:text-sm">
                      Loja Decathlon{' '}
                      <span className="capitalize">
                        {order.business_unit_name.toLowerCase()}
                      </span>
                    </div>

                    <div className="block w-[4px] h-[4px] rounded-round mx-4 bg-darkGray">
                      {' '}
                    </div>

                    <div className="text-xs sm:text-sm">
                      {currency.format(order.amount)}
                    </div>
                  </div>
                </div>
                <div className="purchase-cta hidden sm:flex underline text-sm font-semibold cursor-pointer">
                  Detalhes
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  ) : (
    <NoOrders />
  )
}

export default PhysicalStoreOrders
