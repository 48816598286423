import type { RouteComponentProps } from '@reach/router'
import { HeaderContent, OrderDetails } from 'src/components/sections/Account'

import 'src/styles/pages/myaccount.scss'

interface Props extends RouteComponentProps {
  orderId?: string
}

function Order({ orderId }: Props) {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center mx-auto md:flex-row md:items-start max-w-7xl">
        <div className="flex flex-col items-center w-full md:max-w-5xl md:items-unset">
          <HeaderContent
            {...{
              content: 'Minhas Compras',
            }}
          />
          <OrderDetails
            {...{
              classContainer:
                'mx-auto w-[90%] md:w-11/12 shadow-md bg-white rounded',
              orderId,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Order
