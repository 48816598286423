/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { AccountContext } from 'src/components/account/context'
import { SliderLayout } from 'src/components/common/SliderLayout/SliderLayout'
import AlertAccount from 'src/components/ui/AlertAccount'
import Loader from 'src/components/ui/Loader'
import { Image } from 'src/components/ui/Image'
import useStorage from 'src/sdk/hooks/useStorage'
import type { UserDecathlon } from 'src/components/account/types'

import './styles.scss'

interface ISport {
  Sports_id: string
}

const Sport = ({ classContainer }: any) => {
  const {
    memberAuthToken,
    setAlertSuccess,
    alertSuccess,
    setAlertError,
    alertError,
  } = useContext(AccountContext)

  const [, setUserDecathlon] = useState<UserDecathlon | null>(null)
  const { storageGetItem, storageSetItem } = useStorage()

  const [sportsCategory, setSportsCategory] = useState([])
  const [allSports, setAllSports] = useState([])
  const [contentSportView, setContentSportView] = useState([])
  const [choosenSport, setChoosenSport] = useState<ISport[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [tabMenu, setTabMenu] = useState<number>(-1)
  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

  const getSports = async () => {
    setLoading(true)
    const userSportsData: any =
      JSON.parse(storageUserDecathlon).getSportsPractice

    return userSportsData
  }

  const handleSportCategory = (sportCategory: string) => {
    const peerUniverse = allSports.filter(
      (item: any) => item.universo === sportCategory
    )

    setContentSportView(peerUniverse)
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const [PRACTICE, response] = await Promise.all([
        await getSports(),
        await axios.get(
          `https://decathlonstore.myvtex.com/api/dataentities/SP/search?_fields=id,image,name,name_english,Sports_id,sports_practice,universo`,
          {
            headers: {
              'rest-range': 'resources=0-100',
            },
          }
        ),
      ])

      const reduced: any = []

      response.data.forEach((item: any) => {
        const duplicated =
          reduced.findIndex((redItem: any) => {
            return item.universo === redItem.universo
          }) > -1

        !duplicated ? reduced.push(item) : null
      })

      const allSportsCategory = reduced?.map((sport: any) => sport.universo)
      const sportsContent = response?.data

      const mainSports = sportsContent?.filter((sport: any) => {
        const mainSportsCheck = PRACTICE?.filter(
          (praticeItem: any) =>
            Number(praticeItem.sport_id) === Number(sport.Sports_id)
        )

        return Boolean(mainSportsCheck?.length)
      })

      PRACTICE !== undefined && PRACTICE !== null && setLoading(false)

      setChoosenSport(mainSports)
      setAllSports(response.data)
      setContentSportView(response.data)
      setSportsCategory(allSportsCategory?.sort())
    }

    if (storageUserDecathlon) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageUserDecathlon])

  const getUserDecathlon = async () => {
    const { data } = await axios.post('/api/account/getUserDecathlon', {
      memberAuthToken,
    })

    setLoading(false)
    setUserDecathlon(data)
    storageSetItem('user-decathlon', JSON.stringify(data), 'local')
  }

  const addSport = async (SportsID: string) => {
    const response = await axios.post('/api/account/addSportPractice', {
      memberAuthToken,
      data: { sport_id: SportsID },
    })

    if (response.status === 200) {
      const sport = allSports.find(
        (sportItem: any) => sportItem.Sports_id === SportsID
      )

      getUserDecathlon()

      sport && setChoosenSport((state) => [...state, sport])
      setAlertSuccess(true)
      setLoading(false)
    } else {
      setAlertError(true)
      setLoading(false)
    }
  }

  const deleteSport = async (SportsID: string) => {
    const response = await axios.post('/api/account/removeSportPractice', {
      memberAuthToken,
      data: { sport_id: SportsID },
    })

    if (response.status === 200) {
      setChoosenSport((state) =>
        state.filter((sport) => sport.Sports_id !== SportsID)
      )
      getUserDecathlon()
      setAlertSuccess(true)
      setLoading(false)
    } else {
      setAlertError(true)
    }
  }

  const hasSportInSelectedSports = (SportsID: string): boolean => {
    return choosenSport.some((sport) => sport.Sports_id === SportsID)
  }

  const handleActionSport = (sportId: string) => {
    hasSportInSelectedSports(sportId) ? deleteSport(sportId) : addSport(sportId)
  }

  const handleCategoriesImages = (): string[] => {
    return [
      '/arquivos/esportes_destaque-yoga.png',
      '/arquivos/esportes_destaque-jiu-jitsu.png',
      '/arquivos/esportes_destaque-camping.png',
      '/arquivos/esportes_destaque-ciclismo-mountain-bike.png',
      '/arquivos/esportes_destaque-polo-aquatico.png',
      '/arquivos/esportes_destaque-trail-runnig.png',
      '/arquivos/esportes_destaque-hidroginastica.png',
      '/arquivos/esportes_destaque-badminton.png',
      '/arquivos/esportes_destaque-arco-flecha.png',
      '/arquivos/esportes_destaque-patinetes.png',
      '/arquivos/esportes_destaque-padel.png',
    ]
  }

  return (
    <div className={`${classContainer} w-[90%] !pb-0 relative`}>
      <div
        className={
          choosenSport.length >= 1
            ? 'max-w-[90%] min-h-[80px] mx-auto relative'
            : 'hidden'
        }
      >
        {loading ? (
          <Loader mini />
        ) : (
          <SliderLayout
            slidesPerView={2}
            spaceBetween={0}
            navigation
            breakpoints={{
              '480': {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              '768': {
                slidesPerView: 6,
                slidesPerGroup: 3,
              },
            }}
            sliderContainerClasses="w-full"
            sliderName="sportCategories"
          >
            {choosenSport.map((sport: any, i: number) => (
              <div
                key={i}
                sports-id={sport.Sports_id}
                id={sport.id}
                className="flex justify-center items-center"
              >
                <div className="flex flex-col justify-center items-center text-sm w-full m-[10px]">
                  <Image
                    className="max-w-[50px] mx-auto"
                    src={`https://decathlonstore.vtexassets.com/${sport.image}`}
                    alt={sport.name}
                    height={64}
                    width={64}
                    loading="lazy"
                  />
                  <label>{sport.name}</label>
                </div>
              </div>
            ))}
          </SliderLayout>
        )}
      </div>
      <>
        <div className="flex border-t-[#007cb9] border-t-2 flex-col md:flex-row">
          <div className="max-w-full">
            <div className="hideScrollbar flex w-full overflow-auto flex-row md:flex-col h-fit">
              <div
                className={`p-2 md:p-4 w-full min-w-[110px] md:min-w-unset cursor-pointer flex flex-row items-center text-sm border-b-4 md:border-b-0 md:border-l-4 ${
                  tabMenu === -1
                    ? 'border-[#007cb9] bg-[#fff] text-blue'
                    : 'border-[#e5e5e5] bg-[#e5e5e5]'
                }`}
              >
                <button
                  onClick={() => {
                    setContentSportView(allSports)
                    setTabMenu(-1)
                  }}
                >
                  TODOS OS ESPORTES
                </button>
              </div>
              {sportsCategory.map((sportCategory: string, i: number) => (
                <button
                  key={i}
                  onClick={() => {
                    handleSportCategory(sportCategory)
                    setTabMenu(i)
                  }}
                  className={`p-2 md:p-4 w-full min-w-[110px] md:min-w-max cursor-pointer flex flex-col md:flex-row justify-center md:justify-start items-center text-sm border-b-4 md:border-l-4 ${
                    tabMenu === i
                      ? 'border-[#007cb9] bg-[#fff] text-blue'
                      : 'border-[#e5e5e5] bg-[#e5e5e5]'
                  }`}
                >
                  <Image
                    src={`https://decathlonstore.vtexassets.com${
                      handleCategoriesImages()[i]
                    }`}
                    alt={sportCategory}
                    className="mr-[10px]"
                    width={25}
                    height={25}
                    loading="lazy"
                  />
                  {sportCategory}
                </button>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-3 justify-items-center max-h-[650px] w-full overflow-y-scroll items-baseline grid-rows-[min-content]">
            {contentSportView.map((sport: any, i: number) => (
              <div
                key={i}
                sports-id={sport.Sports_id}
                id={sport.id}
                className="p-3 xs:p-5 w-full flex justify-center"
              >
                <button
                  onClick={() => {
                    setLoading(true)
                    handleActionSport(sport.Sports_id)
                  }}
                  className={
                    choosenSport.includes(sport)
                      ? 'text-[#007cb9]'
                      : 'grayscale hover:grayscale-0 hover:text-[#007cb9]'
                  }
                >
                  <div className="flex justify-center">
                    <Image
                      src={`https://decathlonstore.vtexassets.com/${sport.image}`}
                      className="xs:w-24 xs:h-24"
                      height={64}
                      width={64}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <label className="text-xs">{sport.name}</label>
                </button>
              </div>
            ))}
          </div>
        </div>
      </>
      <AlertAccount
        {...{
          alertSuccess,
          alertError,
          textSuccess: `Meus esportes alterados com sucesso!`,
          textError: `Não foi possível alterar os seus esportes`,
        }}
      />
    </div>
  )
}

export { Sport }

export default Sport
