const ArrowWhite = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.08395 3.64645C6.27921 3.45118 6.59579 3.45118 6.79105 3.64645L10.7911 7.64645C10.9863 7.84171 10.9863 8.15829 10.7911 8.35355L6.79105 12.3536C6.59579 12.5488 6.27921 12.5488 6.08395 12.3536C5.88868 12.1583 5.88868 11.8417 6.08395 11.6464L9.73039 8L6.08395 4.35355C5.88868 4.15829 5.88868 3.84171 6.08395 3.64645Z"
      fill="white"
    />
  </svg>
)

export default ArrowWhite
