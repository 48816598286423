/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, memo } from 'react'
import { useField } from '@unform/core'
import InputMask from 'react-input-mask'

const Input = ({
  name,
  label,
  classContainer = '',
  mask,
  value,
  onChange,
  type,
  placeHolder,
  required,
  disabled,
  ...rest
}: any) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue = '', registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <div
      className={`flex flex-col sm:w-1/2 w-full bg-[#ffffff] ${classContainer}`}
    >
      {label && (
        <label
          className={`text-[#333] pb-2 ${classContainer}`}
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <InputMask
        ref={inputRef}
        id={fieldName}
        defaultValue={defaultValue}
        error={!!error}
        mask={mask || null}
        value={value || null}
        name={name || null}
        placeholder={placeHolder || null}
        type={type || null}
        required={required || null}
        disabled={disabled}
        {...rest}
      />
      {error && (
        <small className="mt-1 text-restructure-error font-inter">
          {error}
        </small>
      )}
    </div>
  )
}

export { Input }
export default memo(Input)
