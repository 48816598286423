import { selectOptions } from 'src/components/sections/Account/Contents/Profile/Bodys/Address/Form/selectOptions'
import type { InputHTMLAttributes } from 'react'

interface AddressInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  label: string
  labelFor: string
  name: string
  readonly?: boolean
  className: string
}

const StateSelect = ({
  label,
  labelFor,
  name,
  readOnly = false,
  className,
  ...rest
}: AddressInputProps) => {
  return (
    <div className={`${readOnly && 'opacity-[.38]'} ${className}`}>
      <label className="flex flex-col gap-1 " htmlFor={labelFor}>
        <span className="font-inter text-sm font-normal">{label}</span>
        <select
          disabled={readOnly}
          className="address-input border border-neutral04 text-black font-inter text-sm py-5 px-4"
          name={name}
          id={labelFor}
          {...rest}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}

export default StateSelect
