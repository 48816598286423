/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import { getCookie } from 'src/utils/getCookie'

import LoadingSVG from '../../Loaders/LoadingSVG'
import Timeline from '../Orders/Timeline'
import OrderSummary from './OrderSummary'
import OrderProducts from './OrderProducts'
import OrderShipping from './OrderShipping'

const OrderDetails = ({ classContainer, orderId }: any) => {
  const [orders, setOrders] = useState<any>()

  const memberAuthToken = getCookie('memberAuthToken')

  useEffect(() => {
    const getOrderList = async () => {
      const { data } = await axios.post('/api/getOrderList', {
        email: memberAuthToken,
        page: 1,
      })

      setOrders(data.response.data.MY_ACCOUNT_ORDER_LIST)
    }

    getOrderList()
  }, [memberAuthToken])

  if (!orders) {
    return (
      <div className={classContainer}>
        <LoadingSVG />
      </div>
    )
  }

  const order = orders?.list.filter(
    (orderItem: any) => orderItem.orderId === orderId
  )[0]

  const formatData = (dataForm: string) => {
    const dateObj = dataForm

    return new Date(dateObj).toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
  }

  const { packageAttachment, items, sellers, timeline } = order

  return (
    <>
      {order ? (
        <div className={classContainer}>
          <article className="order">
            <section>
              <span>{formatData(order.creationDate)}</span>
            </section>

            <Timeline timeline={timeline} order={order} />

            <OrderSummary order={order} />

            <OrderShipping packageAttachment={packageAttachment} />

            <OrderProducts items={items} sellers={sellers} />
          </article>
        </div>
      ) : (
        navigate('/account/orders')
      )}
    </>
  )
}

export { OrderDetails }
export default OrderDetails
