import { useCallback } from 'react'

import type { LinxMetaAccount } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

export const useLinxAccountView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const sendAccountViewEvent = useCallback(() => {
    const linxMetaAccount: LinxMetaAccount = {
      page: 'userprofile',
    }

    return dispatchLinxEvent(linxMetaAccount)
  }, [dispatchLinxEvent])

  return { sendAccountViewEvent }
}
