/* eslint-disable @typescript-eslint/no-explicit-any */
import './styles.scss'

const OrderShipping = ({ packageAttachment }: any) => {
  const { packages } = packageAttachment

  const formatData = (data: string) => {
    const dateObj = data

    return new Date(dateObj).toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
  }

  const formatTime = (data: string) => {
    const timeObj = data

    return new Date(timeObj).toLocaleString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return (
    <section className="shipping">
      {packages.map(
        (
          { trackingNumber, trackingUrl, courierStatus }: any,
          index: number
        ) => (
          <div key={index}>
            {courierStatus?.data && (
              <div className="courierStatusData">
                <div>
                  <span>DATA</span>
                  <span>HORA</span>
                  <span>STATUS</span>
                </div>

                {courierStatus?.data.map(
                  ({ lastChange, description }: any, i: number) => (
                    <div key={i}>
                      <span>{formatData(lastChange)}</span>
                      <span>{formatTime(lastChange)}</span>
                      <span>{description}</span>
                    </div>
                  )
                )}
              </div>
            )}
            {trackingUrl && (
              <a href={trackingUrl} target="_blank" rel="noreferrer">
                Ver rastreio no site da transportadora
              </a>
            )}
            <p>
              {trackingNumber && (
                <p>{`Código de rastreio: ${trackingNumber}`}</p>
              )}
            </p>
          </div>
        )
      )}
      <br />
      <span>
        <strong>Atenção:</strong> Em alguns casos, por conta das dimensões e
        pesos dos produtos, os mesmos podem ser entregues separadamente.
      </span>
      <br />
    </section>
  )
}

export default OrderShipping
