export function changeVtexImageSize(
  imgUrl: string | undefined,
  newSize: string
) {
  if (!imgUrl?.includes('/ids/')) {
    return imgUrl
  }

  try {
    const urlParts = imgUrl.split('/ids/')
    const imgId = urlParts[1].substring(0, urlParts[1].indexOf('-'))

    return `${urlParts[0]}/ids/${imgId}-${newSize}/${urlParts[1].split('/')[1]}`
  } catch (error) {
    console.error(error)

    return imgUrl
  }
}
