/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import './styles.scss'

const Timeline = ({ timeline, order }: any) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 650)

  window.addEventListener('resize', () => setIsDesktop(window.innerWidth > 650))

  const {
    deliveryType,
    currentStatus,
    orderCreated,
    awaitingPayment,
    paymentConfirmed,
    orderInvoiced,
    orderDispatched,
    orderDelivered,
    orderAvailablePickup,
    orderCanceled,
  } = timeline

  const lengthPackage = order?.packageAttachment.packages.length

  const setIndexNFe = (index: number) => {
    if (lengthPackage === 1) return ''

    if (lengthPackage < 10) return `#0${index + 1} `

    if (lengthPackage >= 10) return `#${index + 1} `

    return ''
  }

  return (
    <section className="timeline">
      <h2>
        Status do Pedido: {!isDesktop && <br />}{' '}
        <strong>{currentStatus}</strong>
      </h2>
      <div className="content">
        {order?.statusDescription === 'Faturado' &&
          order?.packageAttachment.packages &&
          order?.packageAttachment.packages.map(
            ({ invoiceKey }: any, index: number) =>
              invoiceKey && (
                <a
                  key={index}
                  className="orderBodyInfoLink"
                  href={`http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=${invoiceKey}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{`2ª Via Nota Fiscal ${setIndexNFe(index)}`}</span>
                </a>
              )
          )}
      </div>

      <div
        className={`timelineWrapper ${
          orderCanceled.status && 'timelineWrapperCanceled'
        }`}
      >
        <div className="statusWrapper">
          <span className={orderCreated.status ? 'full' : 'empty'} />
          <span
            className={`orderCreated ${
              orderCreated.label === currentStatus && 'current'
            }`}
          >
            {orderCreated.label}
          </span>
        </div>
        <div className="statusWrapper">
          <span className={awaitingPayment.status ? 'full' : 'empty'} />
          <span
            className={`awaitingPayment ${
              awaitingPayment.label === currentStatus && 'current'
            }`}
          >
            {awaitingPayment.label}
          </span>
          {awaitingPayment.url && (
            <a
              className="link"
              href={awaitingPayment.url}
              target="_blank"
              rel="noreferrer"
            >
              Reimprimir {isDesktop && <br />} boleto
            </a>
          )}
        </div>

        {orderCanceled.status && (
          <div className="statusWrapper">
            <span className="canceled" />
            <span className="orderCanceled">{orderCanceled.label}</span>
            {orderCanceled.url && (
              <a
                className="link"
                href={orderCanceled.url}
                target="_blank"
                rel="noreferrer"
              >
                Atendimento {isDesktop && <br />} por email
              </a>
            )}
          </div>
        )}

        {!orderCanceled.status && (
          <>
            <div className="statusWrapper">
              <span className={paymentConfirmed.status ? 'full' : 'empty'} />
              <span
                className={`paymentConfirmed ${
                  paymentConfirmed.label === currentStatus && 'current'
                }`}
              >
                {paymentConfirmed.label}
              </span>
              <a
                className="link"
                href="https://www.decathlon.com.br/servicos/politica-de-entrega"
                target="_blank"
                rel="noreferrer"
              >
                Política {isDesktop && <br />} de Entrega
              </a>
            </div>
            <div className="statusWrapper">
              <span className={orderInvoiced.status ? 'full' : 'empty'} />
              <span
                className={`orderInvoiced ${
                  orderInvoiced.label === currentStatus && 'current'
                }`}
              >
                {orderInvoiced.label}
              </span>
            </div>
            <div className="statusWrapper">
              <span className={orderDispatched.status ? 'full' : 'empty'} />
              <span
                className={`${
                  orderDispatched.label === 'Pedido enviado'
                    ? 'orderDispatched'
                    : 'orderDispatchedPickup'
                } ${orderDispatched.label === currentStatus && 'current'}`}
              >
                {orderDispatched.label}
              </span>
              {orderDispatched.url && (
                <a
                  className="link"
                  href={orderDispatched.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Acompanhar {isDesktop && <br />} rastreio
                </a>
              )}
            </div>

            {deliveryType === 'pickup' && (
              <div className="statusWrapper">
                <span
                  className={
                    timeline.orderAvailablePickup.status ? 'full' : 'empty'
                  }
                />
                <span
                  className={`orderAvailablePickup ${
                    orderAvailablePickup.label === currentStatus && 'current'
                  }`}
                >
                  {orderAvailablePickup.label}
                </span>
              </div>
            )}

            <div className="statusWrapper">
              <span
                className={timeline.orderDelivered.status ? 'full' : 'empty'}
              />
              <span
                className={`orderDelivered ${
                  orderDelivered.label === currentStatus && 'current'
                }`}
              >
                {orderDelivered.label}
              </span>
              {orderDelivered.url && (
                <a
                  className="link"
                  href={orderDelivered.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Efetuar troca {isDesktop && <br />} ou devolução
                </a>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Timeline
