import { useMobile } from 'src/hooks/useMobile'
import Skeleton from 'react-loading-skeleton'

const NewCardSkeleton = () => {
  const { isMobile } = useMobile()

  return (
    <div className="w-full">
      <div className="font-inter text-base font-normal">Última compra</div>

      <Skeleton width={isMobile ? 348 : 260} height={isMobile ? 348 : 260} />

      <div className="flex max-w-[260px] justify-between mt-5">
        <Skeleton width={57} height={18} />
        <Skeleton width={106} height={18} />
      </div>
      <div>
        <Skeleton width={112} height={18} />
        <Skeleton width={83} height={24} />
        <Skeleton className="mt-6" width={185} height={18} />
      </div>
    </div>
  )
}

export default NewCardSkeleton
