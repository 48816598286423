const ArrowLink = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.82246 1.55297L3.03346 1.55297L3.03346 0.0529696L10.3831 0.0529695L10.3831 7.40263L8.88312 7.40263L8.88312 2.61363L1.44247 10.0543L0.381806 8.99362L7.82246 1.55297Z"
      fill="#101010"
    />
  </svg>
)

export default ArrowLink
