import { navigate } from 'gatsby'
import type { UserProfile } from 'src/components/account/types'

export function checkUserDataIsComplete(userProfile: UserProfile) {
  try {
    const {
      claims: {
        given_name: givenName,
        family_name: familyName,
        phone_number: PhoneNumber,
      },
      additional_information: additionalInformation,
    } = userProfile

    const nationalIdentifier = additionalInformation?.find(
      (information: { id: string }) => {
        return Object.entries(information).find(
          ([_, value]) => value === 'cpf' || value === 'cnpj'
        )
      }
    )?.value

    if (!givenName || !familyName || !nationalIdentifier || !PhoneNumber) {
      navigate(`/member/update?returnUrl=${window.location.href}`)
    }
  } catch (error) {
    console.error(error)
  }
}
