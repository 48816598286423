/* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Link } from 'gatsby'

const Header = ({ content }: any) => {
  return (
    <div className="w-[90%] md:w-11/12 m-auto my-4 md:mx-6 md:mt-2.5 md:mb-2 flex border-lighestGray bg-[#393939] text-white rounded shadow-md">
      <Link
        to="/account"
        className="w-14 p-4 bg-blue border-r-2 border-lighestGray relative my-account-back mr-2.5 rounded-sm hover:brightness-90"
      >
        <div className="relative arrow-left-white" />
      </Link>
      <div className="p-4 uppercase italic">{content}</div>
    </div>
  )
}

export { Header as HeaderContent }
export default Header
