import type { InputHTMLAttributes } from 'react'

interface AddressInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label: string
  labelFor: string
  name: string
  readonly?: boolean
  errorMessage?: string
}

const AddressInput = ({
  className,
  label,
  labelFor,
  name,
  readOnly = false,
  errorMessage,
  ...rest
}: AddressInputProps) => {
  return (
    <div className={`${readOnly ? 'opacity-[.38]' : ''} ${className ?? ''}`}>
      <label className="flex flex-col gap-1" htmlFor={labelFor}>
        <span className="font-inter text-sm font-normal">{label}</span>
        <input
          className="address-input border border-neutral04 text-black font-inter text-sm py-5 px-4"
          type="text"
          name={name}
          id={labelFor}
          disabled={readOnly}
          {...rest}
        />
        {errorMessage && (
          <small className="text-restructure-error font-inter">
            {errorMessage}
          </small>
        )}
      </label>
    </div>
  )
}

export default AddressInput
