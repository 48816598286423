import type { RouteComponentProps } from '@reach/router'
import {
  HeaderContent,
  StoreOrders as StoreOrdersSection,
} from 'src/components/sections/Account'

import 'src/styles/pages/myaccount.scss'

function StoreOrders(_: RouteComponentProps) {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center py-4 mx-auto md:flex-row md:items-start md:py-16 max-w-7xl">
        <div className="flex flex-col items-center w-full md:max-w-5xl md:items-unset">
          <HeaderContent
            {...{
              content: 'Minhas Compras',
            }}
          />
          <StoreOrdersSection
            {...{
              classContainer: 'mx-auto md:w-11/12 shadow-md rounded',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default StoreOrders
