import Skeleton from 'react-loading-skeleton'
import { useMobile } from 'src/hooks/useMobile'

import 'react-loading-skeleton/dist/skeleton.css'

export const NoOrdersSkeleton = () => {
  const { isMobile } = useMobile()

  const skeletons = Array.from({ length: 5 }, (_, index) => (
    <div
      key={index}
      className="w-full flex mt-[32px] mb-8 border-b border-[#E1E4E7] pb-[32px]"
    >
      <Skeleton height={64} width={64} className="rounded-4" />

      <div className="w-full ml-4 mt-2 flex flex-col ">
        <Skeleton height={18} width={152} />

        <Skeleton
          className="mt-2 rounded-4"
          height={18}
          width={isMobile ? 182 : 357}
        />
      </div>

      <div className="self-start ml-10">
        <Skeleton height={18} width={isMobile ? 33 : 66} />
      </div>
    </div>
  ))

  return <>{skeletons}</>
}
