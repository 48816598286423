export function EllipsisIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.25C11.8619 12.25 11.75 12.3619 11.75 12.5C11.75 12.6381 11.8619 12.75 12 12.75C12.1381 12.75 12.25 12.6381 12.25 12.5C12.25 12.3619 12.1381 12.25 12 12.25ZM10.25 12.5C10.25 11.5335 11.0335 10.75 12 10.75C12.9665 10.75 13.75 11.5335 13.75 12.5C13.75 13.4665 12.9665 14.25 12 14.25C11.0335 14.25 10.25 13.4665 10.25 12.5Z"
        fill="#007DBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.25C11.8619 5.25 11.75 5.36193 11.75 5.5C11.75 5.63807 11.8619 5.75 12 5.75C12.1381 5.75 12.25 5.63807 12.25 5.5C12.25 5.36193 12.1381 5.25 12 5.25ZM10.25 5.5C10.25 4.5335 11.0335 3.75 12 3.75C12.9665 3.75 13.75 4.5335 13.75 5.5C13.75 6.4665 12.9665 7.25 12 7.25C11.0335 7.25 10.25 6.4665 10.25 5.5Z"
        fill="#007DBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.25C11.8619 19.25 11.75 19.3619 11.75 19.5C11.75 19.6381 11.8619 19.75 12 19.75C12.1381 19.75 12.25 19.6381 12.25 19.5C12.25 19.3619 12.1381 19.25 12 19.25ZM10.25 19.5C10.25 18.5335 11.0335 17.75 12 17.75C12.9665 17.75 13.75 18.5335 13.75 19.5C13.75 20.4665 12.9665 21.25 12 21.25C11.0335 21.25 10.25 20.4665 10.25 19.5Z"
        fill="#007DBC"
      />
    </svg>
  )
}
