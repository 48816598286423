/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react'
import { AccountContext } from 'src/components/account/context'

import LoadingSVG from '../../Loaders/LoadingSVG'
import StoreOrdersItens from './StoreOrdersItens'

const StoreOrders = ({ classContainer }: any) => {
  const { userDecathlon } = useContext(AccountContext)
  // const [getPurchase, getPurchaseData] = useState<any>()
  const [userPurchaseData, setPurchaseData] = useState<any>()

  useEffect(() => {
    if (userDecathlon) {
      setPurchaseData(userDecathlon?.getPurchase)
    }
  }, userPurchaseData)

  if (userPurchaseData === undefined) {
    return (
      <div className={`${classContainer} bg-white`}>
        <LoadingSVG />
      </div>
    )
  }

  return userPurchaseData.length !== 0 ? (
    <div className={classContainer}>
      {userPurchaseData?.map((order: any, index: number) => (
        <StoreOrdersItens key={index} order={order} />
      ))}
    </div>
  ) : (
    <div className={classContainer}>
      <div className="container flex-col sm:flex-row mx-auto px-10 py-5 flex items-center justify-around bg-white rounded-lg">
        Não foi encontrado nenhuma compra!
      </div>
    </div>
  )
}

export { StoreOrders }
export default StoreOrders
