import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useMobile } from 'src/hooks/useMobile'
import { useEffect } from 'react'
import { useAccountContext } from 'src/components/account/context/account-context'
import { useLinxAccountView } from 'src/sdk/linx/hooks/useLinxAccountView'
import { checkUserDataIsComplete } from 'src/middlewares/checkUserData'

import { loginUrl } from '../../../store.config'
import SideMenu from '../../components/account/SideMenu'
import Content from '../../components/sections/Account/Content'

function Account() {
  const { screenWidth } = useMobile()
  const { userDecathlon } = useAccountContext()
  const { sendAccountViewEvent } = useLinxAccountView()

  useEffect(() => {
    if (document.cookie.indexOf('memberAuthToken') < 0) {
      window.location.href = loginUrl

      return
    }

    if (userDecathlon?.getUserProfile) {
      checkUserDataIsComplete(userDecathlon?.getUserProfile)
    }

    sendAccountViewEvent()
  }, [sendAccountViewEvent, userDecathlon?.getUserProfile])

  return (
    <>
      <GatsbySeo title="Minha Conta | Decathlon" />
      <div className="flex flex-col items-center justify-center py-8 mx-auto md:justify-normal md:flex-row md:items-start px-md md:py-16 max-w-7xl font-inter">
        {screenWidth >= 900 && <SideMenu />}
        <Content />
      </div>
    </>
  )
}

export default Account
