const Check = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4373 3.5498C7.75152 3.5498 3.9873 7.31402 3.9873 11.9998C3.9873 16.6856 7.75152 20.4498 12.4373 20.4498C17.1231 20.4498 20.8873 16.6856 20.8873 11.9998V11.2998H22.3873V11.9998C22.3873 17.514 17.9515 21.9498 12.4373 21.9498C6.92309 21.9498 2.4873 17.514 2.4873 11.9998C2.4873 6.48559 6.92309 2.0498 12.4373 2.0498C14.4719 2.0498 16.3294 2.5868 17.8732 3.6895L17.0014 4.9101C15.7452 4.01281 14.2027 3.5498 12.4373 3.5498ZM22.3676 6.03013L12.8676 15.5301C12.5952 15.8026 12.2362 15.8998 11.9373 15.8998C11.6384 15.8998 11.2795 15.8026 11.007 15.5301L7.70697 12.2301L8.76763 11.1695L11.9373 14.3391L21.307 4.96947L22.3676 6.03013Z"
      fill="#15181B"
    />
  </svg>
)

export default Check
