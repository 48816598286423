/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'gatsby'

import { formatMoney } from '../../../utils/format-money-string'
import './styles.scss'

const StoreOrdersItens = ({ order }: any) => {
  return (
    <div className="container flex-col sm:flex-row mx-auto my-4 px-10 py-5 flex items-center justify-around bg-white rounded-lg">
      <p className="flex flex-row sm:flex-col text-center uppercase my-1">
        <strong className="db-m bold-text">Loja Decathlon:</strong>
        <span>{order.transaction.business_unit_name}</span>
      </p>
      <p className="flex flex-row sm:flex-col text-center uppercase my-1">
        <strong className="db-m bold-text">Data da compra: </strong>
        <span>{order.transaction.transaction_date_time}</span>
      </p>
      <p className="flex flex-row sm:flex-col text-center uppercase my-1">
        <strong className="db-m bold-text">Valor total: </strong>
        <span>{formatMoney(order.transaction.amount)}</span>
      </p>
      <p className="mt-2">
        <Link
          className="uppercase oneid-orders-button "
          to={`/account/store-order/${order.transaction.transaction_id}`}
        >
          ver detalhes
        </Link>
      </p>
    </div>
  )
}

export default StoreOrdersItens
