/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'gatsby'
import './styles.scss'

const formatData = (data: string) => {
  const dateObj = data

  return new Date(dateObj).toLocaleString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })
}

interface OrderProductsProps {
  order: any
}

function OrderItemResume({ order }: OrderProductsProps) {
  return (
    <Link
      to={`/account/order/${order.orderId}`}
      id="OrderItemResume-wrapper"
      className="bg-white block my-3"
    >
      <div className="flex justify-between flex-col sm:flex-row py-5 px-7">
        <div className="orderHeaderInfo">
          <span>DATA DO PEDIDO</span>
          <span className="orderHeaderDate">
            {formatData(order.creationDate)}
          </span>
        </div>
        <div className="orderHeaderInfo">
          <span># {order.orderId}</span>
          <span
            className={`${
              order.statusDescription === 'Cancelado'
                ? 'StatusRed'
                : 'StatusGreen'
            }`}
          >
            {order.statusDescription}
          </span>
        </div>
      </div>
    </Link>
  )
}

export default OrderItemResume
