import type { OrderItem } from 'src/components/account/Orders/types'

interface DeliveredDateProps {
  order: OrderItem
}

export const DeliveredDate = ({ order }: DeliveredDateProps) => {
  let textDeliveredDate = ''

  if (order.timeline?.currentStatus?.label.toLowerCase() === 'cancelado') {
    const data = new Date(
      order.timeline?.workflow[order.timeline?.workflow.length - 1]?.date
    )

    if (data) {
      const day = String(data.getDate()).padStart(2, '0')
      const month = String(data.getMonth() + 1).padStart(2, '0')

      textDeliveredDate = `Cancelado dia ${day}.${month}`
    }
  } else {
    if (
      order.packageAttachment?.packages[0]?.courierStatus?.deliveredDate ===
      undefined
    ) {
      const day = parseInt(
        order.shippingData.logisticsInfo[0].shippingEstimate,
        10
      )

      textDeliveredDate =
        day === 1
          ? `Chega em até ${day} dia útil`
          : `Chega em até ${day} dias úteis`
    }

    if (order.packageAttachment?.packages[0]?.courierStatus?.deliveredDate) {
      const data = new Date(
        order.packageAttachment?.packages[0]?.courierStatus?.deliveredDate
      )

      const day = String(data.getDate()).padStart(2, '0')
      const month = String(data.getMonth() + 1).padStart(2, '0')

      textDeliveredDate = `Chegou dia ${day}.${month}`
    }
  }

  return (
    <span className="w-full font-inter font-semibold leading-6 text-[#000000] text-[0.875rem] sm:text-[1rem] ">
      {textDeliveredDate}
    </span>
  )
}
