/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { Image } from 'src/components/ui/Image'

import { formatMoney, paymentTypes } from '../../../utils'

import './styles.scss'

const OrderSummary = ({ order }: any) => {
  const { shippingData, paymentData, value, totals, statusDescription } = order

  const [informationIsOpen, setInformationIsOpen] = useState(false)

  const groupPaymentTypes = (paymentId: string) => {
    const payments = paymentTypes.find((payment) => payment.id === paymentId)

    return payments?.group
  }

  const iconsPaymentTypes = (paymentId: string) => {
    const payment = paymentTypes.find(
      (paymentItem) => paymentItem.id === paymentId
    )

    return (
      <Image
        className="paymentType"
        src={`https://decathlonpro.vteximg.com.br/arquivos/${payment?.icon}`}
        alt={payment?.name}
        width={15}
        height={15}
        loading="lazy"
      />
    )
  }

  return (
    <section className="summary">
      <article className="address">
        <h3>ENDEREÇO</h3>

        {shippingData.selectedAddresses.map(
          (
            {
              receiverName,
              street,
              number,
              complement,
              neighborhood,
              city,
              state,
              postalCode,
              country,
            }: any,
            index: number
          ) => (
            <span key={index}>
              <strong>{receiverName}</strong>
              <br />
              {street} {number}, {complement}
              <br />
              {neighborhood} - {city} - {state}
              <br />
              {postalCode}
              <br />
              {country === 'BRA' ? 'Brasil' : country}
            </span>
          )
        )}
      </article>

      <article className="payment">
        <h3>FORMA DE PAGAMENTO</h3>

        {paymentData.transactions.map(({ payments }: any) =>
          payments.map(
            ({
              paymentSystem,
              lastDigits,
              installments,
              value: orderValue,
              connectorResponses,
            }: any) => (
              <div key={paymentSystem}>
                <div>
                  {iconsPaymentTypes(paymentSystem)}
                  <span>
                    {groupPaymentTypes(paymentSystem)}
                    {lastDigits && ` com final ${lastDigits}`}
                    {` ${formatMoney(orderValue)} (${installments} x)`}
                  </span>
                </div>

                {paymentData?.transactions[0].payments[0].paymentSystemName ===
                  'Boleto Bancário' &&
                statusDescription !== 'Cancelado' &&
                statusDescription !== 'Faturado' ? (
                  <a
                    className="linkBoleto"
                    href={paymentData?.transactions[0].payments[0].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <BoletoIcon /> */}
                    <span>Reimprimir boleto bancário</span>
                  </a>
                ) : (
                  <section className="additionalInformation">
                    {Object.entries(connectorResponses).length > 0 && (
                      <>
                        <button
                          onClick={() =>
                            setInformationIsOpen(!informationIsOpen)
                          }
                        >
                          <span
                            className={
                              informationIsOpen ? 'inconClose' : 'inconOpen'
                            }
                          />
                          Informações adicionais
                        </button>
                        {informationIsOpen && (
                          <ul>
                            {Object.entries(connectorResponses).map(
                              ([key, vle]: any, index: number) => (
                                <li key={index}>{vle && `${key}: ${vle}`}</li>
                              )
                            )}
                          </ul>
                        )}
                      </>
                    )}
                  </section>
                )}
              </div>
            )
          )
        )}
      </article>

      <article className="totalizers">
        <h3>RESUMO</h3>

        <div>
          <span>Subtotal</span>
          <span>{formatMoney(totals[0].value)}</span>
        </div>

        {totals[2].value !== 0 && (
          <div>
            <span>Entrega</span>
            <span>{formatMoney(totals[2].value)}</span>
          </div>
        )}

        {totals[3].value !== 0 && (
          <div>
            <span>Taxa</span>
            <span>{formatMoney(totals[1].value)}</span>
          </div>
        )}

        {totals[1].value !== 0 && (
          <div>
            <span>Desconto</span>
            <span>{`-${formatMoney(Math.abs(totals[1].value))}`}</span>
          </div>
        )}

        <div>
          <span>Total</span>
          <span>{formatMoney(value)}</span>
        </div>
      </article>
    </section>
  )
}

export default OrderSummary
