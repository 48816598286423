import { useRef } from 'react'
import type { MutableRefObject } from 'react'

export function useDebounce(fn: any, delay: number) {
  const timeoutRef:
    | MutableRefObject<null>
    | MutableRefObject<ReturnType<typeof setTimeout>> = useRef(null)

  function debouncedFn(...args: any) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      fn(...args)
    }, delay)
  }

  return debouncedFn
}
